import React from "react";
import propTypes from "prop-types";
import Play from "../../../../../assets/icons/play.svg";
// import BookmarkClick from "./BookmarkClick";
import moment from "moment";

const dateFormatter = date => {
  const updateDate = moment(date).format("DD MMM YYYY");
  return updateDate;
};

const FeaturedBlogCard = ({ url,tag, type, name,isPlay,blogLink }) => {
  return (
    <div className="relative flex rounded-2xl  h-[142px] w-[520px] shadow-outer mb-6 overflow-hidden">
      <a href={blogLink} target="_blank" rel="noreferrer noopener">
        <img src={url} alt="blog image" className="cursor-pointer w-52 h-36 bg-gray-100 rounded-2xl object-cover" loading="lazy"/>
      </a>
      {isPlay && (
        <img
          className="w-8 mt-8 rounded-xl z-60 absolute top-6 left-20"
          src={Play}
          loading="lazy"
        />
      )}
      <div className="pr-6 pl-6 flex flex-col justify-center">
        <div className="flex items-center justify-between mb-4">
          <div className="flex items-center space-x-2 text-[12px] relative">
            {tag && <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm ">
              {dateFormatter(tag)}
            </p>}
            {type && <p className="px-[12px] py-[6px] bg-secondary-200 rounded-full font-ibm">
              {type}
            </p>}
          </div>
          {/* <div className="absolute right-4">
            <BookmarkClick 
              postId={postId}
              isBookMark={isBookMark}
              blogsRefetch={blogsRefetch}
              forFeaturedBlogs = {forFeatureBlogs}
            />
          </div> */}
        </div>
        <a href={blogLink} target="_blank" rel="noreferrer noopener"><p className=" font-medium font-ibm w-72 line-clamp-3" >{name}</p></a>
      </div>
     
    </div>
  );
};

FeaturedBlogCard.propTypes = {
  tag: propTypes.string,
  type: propTypes.string,
  name: propTypes.string,
  url: propTypes.string,
  isPlay: propTypes.bool,
  isUpcoming: propTypes.bool,
  isBookMark: propTypes.string,
  postId: propTypes.string,
  blogsRefetch: propTypes.func,
  blogLink:propTypes.string,
  forFeatureBlogs : propTypes.bool
};

export default FeaturedBlogCard;
