import propTypes from "prop-types";
import React, { useState } from "react";
import Calendar from "../../../global/calendar/Calendar";
import BookingSlotScreen from "./BookingSlotScreen";
import SuccessScreen from "./SuccessScreen";

import { useMutation, useQuery, useQueryClient } from "react-query";
import { useSelector } from "react-redux";
import { apirequest, prepare_apirequest } from "../../../../../API/api";
import { endpoint } from "../../../../../API/config";
import { loginDetails } from "../../../../../redux/features/login/LoginSlice";
import WithModal from "../../../global/WithModal/WithModal";

function BookingSlotModal({ closeModal, variantDetails, asset }) {
  const login = useSelector(loginDetails);
  const [isCalendarOpen, setIsCalendarOpen] = useState(false);
  const [isSlotBooked, setIsSlotBooked] = useState(false);

  const [selectedDate, setSelectedDate] = useState();

  const [bookingData, setBookingData] = useState({
    venue: null,
    venue_name: null,
    date: null,
    slot: null,
    is_take_home: false,
  });

  const queryClient = useQueryClient();

  const getVenues = () => {
    const centre = localStorage.getItem("centre");
    return apirequest({
      url: `${endpoint.getVenuesUrl.addr}`,
      method: "post",
      headers: {
        "access-token": login.token,
      },

      data: {
        user_imspin: login.student_imspin,
        asset_id: Number(asset.assetId),
        window_start: `${asset.assetDetails[0].releaseDate} 00:00:00`,
        window_end: `${asset.assetDetails[0].endDate} 23:59:59`,
        centre_id: Number(centre),
      },
    });
  };
  const getSlots = () => {
    const centre = localStorage.getItem("centre");
    return apirequest({
      url: `${endpoint.getSlotsUrl.addr}`,
      method: "post",
      headers: {
        "access-token": login.token,
      },

      data: {
        user_imspin: login.student_imspin,
        asset_id: Number(asset.assetId),
        window_start: `${asset.assetDetails[0].releaseDate} 00:00:00`,
        window_end: `${asset.assetDetails[0].endDate} 23:59:59`,
        centre_id: Number(centre),
        venue_id: bookingData.venue,
      },
    });
  };

  const bookSlotHandler = async () => {
    const centre = localStorage.getItem("centre");
    return prepare_apirequest({
      url: "/myims/test/slots/bookSlot",
      method: "post",
      headers: {
        "access-token": login.token,
      },
      data: {
        asset_id: Number(asset.assetId),
        slot_id: bookingData.slot,
        centre_id: Number(centre),
        venue_id: bookingData.venue,
        is_take_home: bookingData.is_take_home,
      },
    });
  };

  const { mutate, isSuccess } = useMutation({
    mutationKey: ["Book Slot"],
    mutationFn: bookSlotHandler,
    onSuccess: () => {
      setIsSlotBooked(true);
    },
    onError: () => {
      alert("Failed to book");
    },
    onSettled: () => {},
    onMutate: () => {},
  });

  const bookSlot = () => {
    mutate();
  };

  const { data: venues, isError } = useQuery({
    queryKey: [endpoint.getVenuesUrl.key, asset.assetId],
    queryFn: getVenues,
    select: (data) => data.data.data,
    enabled: !!variantDetails.centreProctoredAllowed,
  });
  const { data: slots } = useQuery({
    queryKey: [endpoint.getSlotsUrl.key, bookingData.venue, asset.assetId],
    queryFn: getSlots,
    select: (data) => data.data.data,
    enabled: !!bookingData.venue,
  });

  return (
    <div>
      {isCalendarOpen ? (
        <Calendar
          availableDates={slots.map((slot) => slot.slot_date)}
          setIsCalendarOpen={setIsCalendarOpen}
          setSelectedDate={(date) => {
            setBookingData((prev) => ({ ...prev, date: date }));
          }}
        />
      ) : isSlotBooked ? (
        <SuccessScreen
          closeModal={() => {
            isSuccess &&
              queryClient.invalidateQueries({
                queryKey: ["Booked Slots"],
              });
          }}
        />
      ) : (
        <BookingSlotScreen
          setIsCalendarOpen={setIsCalendarOpen}
          setIsSlotBooked={setIsSlotBooked}
          closeModal={closeModal}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          variantDetails={variantDetails}
          asset={asset}
          bookingData={bookingData}
          setBookingData={setBookingData}
          bookSlot={bookSlot}
          venues={isError ? null : venues}
          slots={slots ? slots : []}
        />
      )}
    </div>
  );
}

BookingSlotModal.propTypes = {
  asset: propTypes.any,
  closeModal: propTypes.func,
  selectedDate: propTypes.instanceOf(Date),
  variantDetails: propTypes.any,
};

export default WithModal(BookingSlotModal);
