import React,{useState} from "react";
import { loginAPI_apirequest } from "../../../API/api";
import { useQuery } from "react-query";
import { endpoint } from "../../../API/config.jsx";
import Loader from "../global/loader/Loader";
import OtpModalTrigger from "../PreLogin/login/OtpModalTrigger";
import { useHistory } from "react-router-dom";
import loginImage from "../../../assets/images/loginImage.png";
import imslogo from "../../../assets/icons/ims-logo.svg";
// import maskImage from "../../../assets/images/maskImage.png";
import CancelIcon from "../../../assets/icons/cancel-icon-rounded.svg";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import SwiperCore, { Navigation, Autoplay, Pagination, Virtual } from "swiper";
import "swiper/swiper.min.css";
import "swiper/swiper-bundle.css";
import "swiper/swiper-bundle.min.css";
import "./swiper.css";
SwiperCore.use([Navigation, Autoplay, Pagination, Virtual]);
const swiperData = [
  {
    id: 1,
    title: "Experience in shaping success stories",
    para: "IMS’s experienced mentorship aids students in achieving high scores best suited to their objectives, aspirations, and skill sets.",
  },
  {
    id: 2,
    title: "We believe every student's journey is unique",
    para: "IMS creates tailor-made learning paths by recognizing their unique traits and capabilities.",
  },
  {
    id: 3,
    title: "There is limitless possibility",
    para: "IMS ensures that its students are not just exam-ready but also to embark on their next academic journey.",
  },
];
let signUpRedirectionURL = "https://www.imsindia.com/zero-fee-enrollment/?degree=&txtlimacampaign=&utm_source=&utm_medium=&utm_campaign=&utm_term=&utm_content";

const ForgotPassword = () => {
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isSaveandContinueClicked,setIsSaveandContinueClicked] = useState(false);
  const [mobileNumberOrEmail,setMobileNumberOrEmail] = useState("");
  const [verificationDetails,setVerificationDetails] = React.useState();
  const [isgetOTPClicked,setIsgetOTPClicked]= useState(false);

  const [otpModalOpen , setOtpModalOpen] = useState(false);
  const history  = useHistory();

  const handleSaveAndContinue=()=>{
    setIsSaveandContinueClicked(true);
  };

  const inputHandleKeyDown = (e)=>{
    if(e.key == "Enter"){
      mobileNumberOrEmail.length && setOtpModalOpen(true);
    }
  };
  const setNewPasswd = () => {
    return loginAPI_apirequest({
      url: `${endpoint.createNewPassword.addr}`,
      method: "post",
      data: {
        studentEmail : verificationDetails.studentEmail  ??verificationDetails.emailId,
        studentImsPin :verificationDetails.studentImsPin,
        enterNewPassword : newPassword,
      },
    });
  };
  const {isSuccess,isLoading:iscreatePswdLoading} = useQuery([endpoint.createNewPassword.key], setNewPasswd, {
    enabled:!!isSaveandContinueClicked,
    retry:false,
  });
  return (
    <div className="flex font-ibm w-100%">
      {/*******************************LEFT SECTION ***********************************/}
      <div className="w-[60%] max-h-screen">
        <img className="w-[100%] h-[100%]" src={loginImage} alt="login-image" loading="lazy" />
        <div className="w-[60%] h-[50%] relative bottom-80 left-24">
          {/* <img className="w-[100%] " src={maskImage} alt="mask-image" loading="lazy"/> */}
          <div className="h-auto relative bottom  space-y-5">
            
            {/* removed Sign Up static button */}
            <button 
              className="bg-white text-black text-base font-medium px-8 py-2 rounded-xl ml-10"
              onClick={()=>window.open(signUpRedirectionURL , "_blank", "noreferrer")}
            >
              Sign Up
            </button>
           
            <section className="w-[350px] ml-10">
              <Swiper
                slidesPerView={1}
                pagination={true}
                autoplay
                virtual
                itemType="fractionx">
                {swiperData.map(item => (
                  <SwiperSlide key={item.id} className="space-y-5  mb-8">
                    <h1 className="text-3.5xl text-white  font-extralight">
                      {item.title}
                    </h1>
                    <p className="text-lg text-white font-light">{item.para}</p>
                  </SwiperSlide>
                ))}
              </Swiper>
            </section>
          </div>
        </div>
      </div>
    
      {/********************************RIGHT SECTION ***********************************/}
      <div className="w-[40%]">
        <section className="w-full h-auto">
          {/* <div className="w-[100%]"> */}
          <div className="grid grid-cols-1 place-content-center justify-items-center py-5">
            <div className="justify-start">
              <img className="w-20 h-12  my-10" src={imslogo} alt="ims-logo" loading="lazy"/>
              <div>
                <div className="w-[423px]">
                  {!isgetOTPClicked ?<div>
                    <span className="text-xl font-medium">
                  Enter your registered Email ID or IMS Pin to receive an OTP
                    </span>
                    <div className="grid grid-cols-1 space-y-3"  >
                      <input
                        value={mobileNumberOrEmail}
                        onChange={e => setMobileNumberOrEmail(e.target.value)}
                        type="text"
                        className="border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                        placeholder="Registered Email-Id/ IMS Pin"
                        onKeyDown={inputHandleKeyDown}
                      />
                    </div>
                    <OtpModalTrigger 
                      isgetOTPClicked={isgetOTPClicked} 
                      setIsgetOTPClicked={setIsgetOTPClicked} 
                      mobileNumberOrEmail={mobileNumberOrEmail} 
                      setDetails={setVerificationDetails}
                      modalOpen = {otpModalOpen}
                      setShowOtpModal = {setOtpModalOpen}
                    />
                  </div>:
                    <div className="w-[423px]">
                      <span className="text-xl font-medium mb-3">
                    Create New Password
                      </span>
                      <form className="grid grid-cols-1 space-y-3 mt-3" >
                        <input
                          value={newPassword}
                          onChange={e => setNewPassword(e.target.value)}
                          type="password"
                          className={`border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5
                          ${newPassword !== confirmPassword ? "border-2 border-red-500 rounded-2xl py-3 px-3 focus:outline-none mt-5":""}`}
                          placeholder="Enter New Password"
                        />
                        <input
                          value={confirmPassword}
                          onChange={e => setConfirmPassword(e.target.value)}
                          type="password"
                          className={`border-2 border-gray-200 rounded-2xl py-3 px-3 focus:outline-none mt-5"
                      placeholder="Enter New Password ${newPassword !== confirmPassword ? "border-2 border-red-500 rounded-2xl py-3 px-3 focus:outline-none mt-5":""}`}
                          placeholder="Re-Enter New Password"
                        />
                      

                        {!isSuccess?
                          <div className="flex flex-col ">
                            {iscreatePswdLoading ? (
                              <Loader />
                            ) : (
                              <button
                                disabled={newPassword && newPassword == confirmPassword ? false : true}
                                onClick={handleSaveAndContinue}
                                className={`w-full px-24 py-3 rounded-xl text-white text-lg font-medium ${
                      newPassword == confirmPassword && newPassword
                        ? "bg-primary-200"
                        : "bg-[#8E9EAB]"
                    } `}>
                        Save and Continue
                              </button>
                            )}
                          </div>:
                          <div className="flex flex-col items-center my-3 ">
                            <p className="text-primary-1000 text-2xl font-semibold mb-6">Password Created Successfully</p>
                            <button className="bg-primary-100 text-white rounded-2xl font-medium w-44 h-14 flex items-center justify-center" onClick={()=>history.push("/")}>Proceed</button>
                          </div>
                        }
                        {newPassword !== confirmPassword && (
                          <div className="flex h-20">
                            <img src={CancelIcon } alt="cancel" className="w-4 h-4 mt-2" loading="lazy"/>
                            <p className="text-red-500 text-sm font-normal mt-2">
                          Entered Password and confirm password does not match, Try Again
                            </p>
                          </div>
                        )}
                      </form>
                    </div>}
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </div>


    
  );
};

export default ForgotPassword;