import React from "react";
import WithModal from "../../global/WithModal/WithModal";
// import ModalTemplate from "../ModalTemplate/ModalTemplate";
import propTypes from "prop-types";
import { endpoint } from "../../../../API/config";
import {apirequest_liveAPI} from "../../../../API/api";
import { useQuery } from "react-query";
import Loader from "../../global/loader/Loader";

import {loginDetails } from "../../../../redux/features/login/LoginSlice";
import {  useSelector } from "react-redux";

// import ModalTitle from "../ModalTemplate/ModalTitle";
// import {Link } from "react-router-dom";
import { ReactComponent as CloseIcon } from "../../../../assets/icons/cancel.svg";


function ImsLiveModal({closeModal,status,classData}) {
  const LoginDetails = useSelector(loginDetails);
  //for add registrant and update recording first name and lastname has to take from prelogin 
  const profileDetails = useSelector(state => state.prelogin.profileDetails);
  console.log("profileDetails ",profileDetails);

  //   const ImsLiveData=getupdateRecordings();
  const getupdateRecordings = () => {
    
    return apirequest_liveAPI({
      url: `${endpoint.updateRecordings.addr}`,
      method: "put",
      data:{
        email:LoginDetails.studentEmail,
        first_name:profileDetails.firstName,
        last_name:profileDetails.lastName,
        batch_name:classData.batchName,
        module_number:classData.moduleNumber,
        class_title:classData.classTitle,
        class_date:classData.classDate+" "+classData.classTime,
        action:"approve",
        mappingId:classData.mappingId.toString()
      }
    });
  };

  const getLiveClass = () => {
    return apirequest_liveAPI({
      url: `${endpoint.addregistrant.addr}`,
      method: "post",
 
      headers: {
        "access-token": LoginDetails.token
      },
      data: {
        email:LoginDetails.studentEmail,
        first_name:profileDetails.firstName,
        last_name:profileDetails.lastName,
        batch_name:classData.batchName,
        module_number:classData.moduleNumber,
        class_title:classData.classTitle,
        class_date:classData.classDate+" "+classData.classTime,
        imspin:LoginDetails.student_imspin,
        action:"approve",
        mappingId:classData.mappingId.toString()

      },
    });
  };

  let { data: ImsLiveData, isLoading:ImsLiveDataLoading} = useQuery(status=="Live"? endpoint.addregistrant.key:endpoint.updateRecordings.key,status=="Live"?  getLiveClass:getupdateRecordings,{
    // onSuccess:res=>{
    //   if(res.data.status==false || res.data.success==false) { alert(res.data.message); closeModal();}

    // }
  } ); 
  console.log({ImsLiveData});
  return (
    <div className=" w-[450px]">
      {(ImsLiveData?.data.success==false || ImsLiveData?.data.status ==false) ?
        <div className=" h-20 p-5 flex flex-col relative ">
          <span><CloseIcon className="cursor-pointer w-7 h-7 absolute right-4" onClick={closeModal} /></span>
          <p className="text-red-600  font-normal text-base">This recording does not exist.</p>
        </div>:
        (!ImsLiveDataLoading && ImsLiveData? 
          <div className="py-6 px-8 w-full ">
            {status=="video"||status=="Play" && <div>
              <div className="flex items-center justify-between mb-4">
                <p className="text-secondary-600 font-medium text-lg leading-5 font-ibm">
            Guidelines for watching the recorded session
                </p>
                <CloseIcon className="cursor-pointer" onClick={closeModal} />
              </div>
              <div className="mb-7">
      
                <div>
                  <p className="text-sm font-normal text-secondary-600 font-ibm text-justify">
            1. In case if you are prompted to enter your email, please enter the same email: <span className="font-semibold">{LoginDetails.studentEmail} </span>
                  </p>

                  <p className="text-sm font-normal text-secondary-600 font-ibm text-justify">
            2. If prompted enter the password as {<span className="font-semibold">live@ims</span>}
                  </p>
                  <p className="text-sm font-normal text-secondary-600 font-ibm text-justify">
            3. If you are already launched the recording on any other device, then stop watching the recording before launching from here.
                  </p>
                </div>
      
              </div>
            </div>}

            {status=="Live" &&<div>
              <div className="flex items-center justify-between mb-4">
                <p className="text-secondary-600 font-medium text-lg leading-5 font-ibm">
              Guidelines for launching the class
                </p>
                <CloseIcon className="cursor-pointer" onClick={closeModal} />
      
              </div>
              <div className="mb-7">
    
                <div>
                  <p className="text-sm font-normal text-secondary-600 font-ibm text-justify">
          1. Make sure you have the latest version of Zoom app, <a href="https://zoom.us/download"><span className="text-sm font-normal text-primary-200">Click here</span> </a>to download and install it.
                  </p>

                  <p className="text-sm font-normal text-secondary-600 font-ibm text-justify">
          2. Sign-in to Zoom app using the same email you have used during enrollment {<span className="font-semibold">{LoginDetails.studentEmail}</span>} 
                  </p>
                  <p  className="text-sm font-normal text-secondary-600 font-ibm text-justify">In case you are prompted to enter your email, please enter the same email.</p>
                  <p className="text-sm font-normal text-secondary-600 font-ibm text-justify">
          3. If you are already launched the recording on any other device, then stop watching the recording before launching from here.
                  </p>
                </div>
    
              </div>
            </div>}
            <a href={status=="Live"?ImsLiveData.data.joinURL:ImsLiveData.data.share_url} target="_blank" rel="noreferrer noopener">
              <button
                className="px-32 py-4 w-full bg-primary-200 text-base font-medium text-secondary-100 rounded-2xl focus:outline-none focus-visible:ring-offset-2 focus-visible:ring-blue-500">
                {status=="Live"?"Launch":"Okay"}
              </button>
            </a>
          </div>:
          <Loader />)}
    </div>
    
  );
}

ImsLiveModal.propTypes = {
  closeModal: propTypes.func,
  status: propTypes.string,
  classData:propTypes.object
};

export default WithModal(ImsLiveModal);