import React from "react";
import PropTypes from "prop-types";

import InformationIcon from "../../../../assets/icons/information-icon.svg";
import ReviseIcon from "../../../../assets/icons/revise.svg";
import Tooltip from "../../global/tooltip/Tooltip";

const SectionalTestHeader = ({ information, testIds }) => {
  const tooltipText = {
    reviseQue: "Revise the questions across all completed tests.",
  };
  const handleClick = () => {
    if (testIds.length === 0) {
      return;
    }
    window.open(
      `https://bete.imsindia.com/api/test/revision/?test_ids=${testIds}`,
      "Popup"
    );
  };
  return (
    <div className="flex flex-row space-between items-center absolute right-16">
      <div
        className={`flex space-x-2 cursor-pointer ${
          testIds.length === 0 ? "hidden" : "cursor-pointer"
        }`}
      >
        <img src={ReviseIcon} alt="revise" />
        <span
          className="font-ibm font-normal text-lg"
          data-tip
          data-for="registerTip"
          onClick={handleClick}
        >
          Revise
        </span>

        {information && <img src={InformationIcon} alt="information" />}
      </div>
      {
        <Tooltip
          multiline={true}
          id="registerTip"
          place="bottom"
          text={
            <div className="m-0">
              {tooltipText.reviseQue.substring(
                0,
                tooltipText.reviseQue.length / 2
              )}
              <br />
              {tooltipText.reviseQue.substring(
                tooltipText.reviseQue.length / 2,
                tooltipText.reviseQue.length
              )}
            </div>
          }
        />
      }
    </div>
  );
};

SectionalTestHeader.propTypes = {
  information: PropTypes.bool.isRequired,
  testIds: PropTypes.array,
};

export default SectionalTestHeader;
