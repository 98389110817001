import React from "react";
import SubHeaderTab from "./SubHeaderTab";
import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";

import { useSelector, useDispatch } from "react-redux";
import {
  selectSubheaderData,
  selectCurrentHeaderTab,
  selectedSubHeaderTab,
  setSelectedSubHeaderTab,
} from "../../../redux/features/header/headerSlice";

function SubHeader({isFromStudentProfile}) {
  const dispatch = useDispatch();
  const history = useHistory();
  const subHeaderData = useSelector(selectSubheaderData);
  const currentHeaderTab = useSelector(selectCurrentHeaderTab);
  const currentSubHeaderTab = useSelector(selectedSubHeaderTab);
  const learningPathName = useSelector(state => state.header.currentDropdownOption);
  const learningPathId = useSelector(state => state.header.id);
  const handleClick = subHeader => {
    dispatch(setSelectedSubHeaderTab(subHeader.name));
    if (subHeader.isHeader === "1") {
      const subHeaderType = subHeader.type.toLowerCase();
     
      if ((subHeaderType === "prepare" || subHeaderType === "test" || subHeaderType==="performance")) {
        const headerTabId = useSelector(state => state.header.headerId);
        history.push(
          `/${subHeaderType}-${subHeader.name.toLowerCase()}/${subHeader.name}/?parentId=${headerTabId}&id=${subHeader.learningPathId}&learningPathId=${learningPathId}&learningPathName=${learningPathName}`,
        );
      }
      if (subHeaderType === "mypage - mytasks") history.push("/myPage/myTasks");
      if (subHeaderType === "mypage - doubts") history.push("/myPage/doubts");
      if (subHeaderType === "mypage - bookmarks") history.push("/myPage/bookmarks");
      if (subHeaderType === "mypage - myplan") history.push("/myPage/myPlan");

      // School module routing conditions ==========>
      if (subHeaderType.toLowerCase() === "schools - home") history.push("/school/home");
      if (subHeaderType.toLowerCase() === "schools - selectioncriteria") history.push("/school/selection criteria");
      if (subHeaderType.toLowerCase() === "schools - cutoff") history.push("/school/cutoffs");

      if(subHeader.name.toLowerCase() == "dashboard") history.push("/myPlan/dashboard");
      if(subHeader.name.toLowerCase() == "slots") history.push("/myPlan/slots");
      if(subHeader.name.toLowerCase() == "students") history.push("/myPlan/students");
      // if(subHeader.name.toLowerCase() == "predictor") history.push("/performance/predictor");
      // if(subHeader.name.toLowerCase() == "score summary") history.push("/performance/score summary");
    }
  };

  return (
    <div className="flex justify-between items-center shadow-outer ">
      <div className="flex px-14">
        {subHeaderData?.map(
          (subHeader, ind) =>
            subHeader.isActive === "1" && (
              <div key={ind}>
                {subHeader.children ? (
                  <SubHeaderTab
                    isFromStudentProfile={isFromStudentProfile}
                    key={subHeader.learningPathId}
                    subHeaderTabName={subHeader.name}
                    dropDownOptions={subHeader.children}
                  />
                ) : (
                  <div
                    className={`${
                      currentSubHeaderTab?.toLowerCase() ==
                      subHeader.name.toLowerCase()
                        ? "text-base text-primary-200"
                        : "text-secondary-600"
                    } px-6 py-5 font-ibm font-semibold cursor-pointer focus:text-primary-100 hover:text-primary-100`}>
                    <span
                      onClick={() => {
                        handleClick(subHeader);
                      }}>
                      {subHeader.name}
                    </span>
                  </div>
                )}
              </div>
            ),
        )}
      </div>
      {(currentHeaderTab === "myPage" || currentHeaderTab === "school") && (
        <div className="mr-9">{/*<Search isShadow={false} />*/}</div>
      )}
    </div>
  );
}
SubHeader.propTypes = {
  isFromStudentProfile:PropTypes.bool,
};
export default SubHeader;
