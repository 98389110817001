import React from "react";
import {loginDetails } from "./redux/features/login/LoginSlice";
import { useSelector } from "react-redux";
import {Route,Redirect} from "react-router-dom";
import propTypes from "prop-types";

function PrivateRoute({ component:Comp, ...rest }) {
  const LoginDetails = useSelector(loginDetails);
  const isParent = useSelector(state=>state.login.isParent);

  return (
    <Route
      {...rest}
      render={({ location }) => {
        return (isParent || LoginDetails.token ) ? (
          <Comp />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location },
            }}
          />
        );
      }}
    />
  );
}
PrivateRoute.propTypes = {
  component: propTypes.oneOfType([propTypes.elementType || propTypes.func]),
};
export default PrivateRoute;